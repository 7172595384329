import React, { useContext, useEffect, useState } from "react";
import { DatePicker } from "antd";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { Controller, useFormContext } from "react-hook-form";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";


export const DateUi = ({
  elementid,
  showHide,
  oppFields,
  previewFlag = false,
}) => {
  const { board } = useContext(boardConetxt);
  const [ val,setval] = useState(null);
  const disableElementForBuild = window.location.pathname.includes("build")


  const {
    register,
    formState: { errors },
    controls,
    setValue
  } = useFormContext();
  // console.log("<<date>>", showHide)
  useEffect(() => {
    if (oppFields) {
      setValue(elementid, oppFields)
    }
  }, [oppFields, elementid, setValue])


  const Location = useLocation();
  const params = new URLSearchParams(Location.search);

  // useEffect(() => {
  //   if (params.get(elementid.replace(/-\d+$/, ''))) {
  //     setValue(elementid, params.get(elementid.replace(/-\d+$/, '')))
  //   }

  // }, [params.get(elementid.replace(/-\d+$/, ''))])

  const ElementSettingsData = board.find(
    (item) => item?.elementid === elementid
  ).settings;

  const ElementName = board.find((item) => item?.elementid === elementid).name;
  //console.log("date>>", label);
  const { label, instructions, required, hidden } = ElementSettingsData;
  const initialStyle = {
    display: showHide === "show" ? "none" : "block",
  };
  const checkingValidation = () => {
    if ((required === true )&& (showHide === "show")) {
      return false
    } else if (required === false && (showHide === "show")) {
      return false
    } else if ((required === true) && (showHide === "hide")) {
      return false
    } else if ((required === false) && (showHide === "hide")) {
      return false
    } else if ((required === true )&& (hidden === true)) {
      return false
    } else if (required === true) {
      return true
    } else {
      return false
    }

  }
  const normalizeFieldName = (name) => {
    return name?.replace(/[.\s]/g, '_'); // Replace dots and spaces with underscores
  };


  return (
    <div
      
      className={previewFlag && GetStyleClasses(elementid) || ""}
      style={initialStyle}
      id={elementid}
      hidden={hidden && previewFlag}
    >
      <div className="form-floating form-group">

        <Controller
          control={controls}
          name={normalizeFieldName(elementid)}
          rules={{
            required: {
              value: checkingValidation(),
              message: "This field is required",
            },
          }}
          render={({ field }) => (
            <DatePicker
              format="MM/DD/YYYY"
              disabled={disableElementForBuild}
              className="date-picker-input form-control input-bn w-100"
              onChange={(date, dateString) => {
                //console.log("date", dateString);

                field.onChange(dateString);
                setval(date);
              }}
              // value={val}
              id={normalizeFieldName(elementid)}
              defaultValue={oppFields == undefined ? null : dayjs(oppFields)}

              />
          )}
        />


        <label>
          <span className="d-flex align-items-center">
            <i className="iconify me-1 mr-1 fs-5" data-icon="f7:calendar" />
            <span>

              {label == undefined || label == "" ? ElementName : label}




              {required ? <span style={{ color: "red" }}>*</span> : ""}
            </span>
          </span>
        </label>
      </div>


      <small className="text-secondary">{instructions}</small>
      <div className="d-flex flex-column gap-2">
        {errors && (
          <span className="text-danger">{errors[elementid]?.message}</span>
        )}
      </div>

    </div>
  );
};

import { Routes, Route } from "react-router-dom";
import FormList from "./FormList";
import Preview from "./pages/form-builder/preview/Preview";
import { LayoutNavbar } from "./components/LayoutNavbar";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import CreateForm from "./pages/form-builder/dashboard/CreateForm";
import FormEntries from "./pages/form-builder/dashboard/FormEntries";
import OpportunitysList from "./pages/form-builder/dashboard/OpportunitysList";
import Opportunity from "./pages/form-builder/dashboard/Opportunity";
import Calls from "./pages/data-management/analytics/Calls";
import Emails from "./pages/data-management/analytics/Emails";
import Notes from "./pages/data-management/analytics/Notes";
import Text from "./pages/data-management/analytics/Text";

import React, {
  useState,
  useEffect,
  createContext,

} from "react";

import {
  boardConetxt,
  conditionContext,
  LeaderboardContext,
  FormPlanContext,
} from "./pages/form-builder/dashboard/ContextHelper";

import AutomationBuilderDashboard from "./pages/automation-builder/AutomationBuilderDashboard";
import EditWorkflow from "./pages/automation-builder/workflows/EditWorkflow";
import ViewOnlyWorkflow from "./pages/automation-builder/workflows/ViewOnlyWorkflow";
import CreateWorkflow from "./pages/automation-builder/workflows/CreateWorkflow";

import DashBoardForFormBuilder from "./pages/form-builder/dashboard/DashBoardForFormBuilder";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  

} from "@apollo/client";
import { FormProvider, useForm } from "react-hook-form";
import FormEntryData from "./pages/form-builder/dashboard/FormEntryData";
import PrivateRoute from "./PrivateRoute";
import ThankyouPage from "./pages/form-builder/dashboard/ThankyouPage";
import SettingsPage from "./pages/form-builder/dashboard/SettingsPage";
import ConversionRatio from "./pages/form-builder/dashboard/ConversionRatio";
import ConditionDashboard from "./pages/form-builder/conditions/ConditionDashboard";
// import { UpdateOpportunity } from "./pages/EmbeddedApp/CreateOpportunity";
// import { CreateOpportunity } from "./pages/EmbeddedApp/UpdateOpportunity";
import { UpdateOpportunity } from "../src/pages/EmbeddedApp/UpdateOpportunity";
import { CreateOpportunity } from "../src/pages/EmbeddedApp/CreateOpportunity";
import { ListView } from "./pages/EmbeddedApp/ListView";
import { NewLeaderBoard } from "./pages/form-builder/dashboard/NewLeaderBoard";
import FieldMappingDashboard from "./pages/field-mapping/FieldMappingDashboard";



const client = new ApolloClient({

  // uri: "http://localhost:4000/graphql",
  uri: "https://api.sandbox.datalabz.re/graphql",
  // uri: "https://api.datalabz.re/graphql",

  cache: new InMemoryCache(),
  credentials: "include",
  // link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    },
  },
});
// const teamSettings = gql`
// query GetTeamSettings {
//   GetTeamSettings {
//     team_id
   
//   }
// }`;

const UserTriggersList = createContext();
const WorkflowsList = createContext();
const userdetails = createContext();

const PlanDetails = {
  Free: [
    {
      planName: "Free",
      FormLimit: 1,
      EntryLimit: 100,
      FieldLimit: 50,
      CustomFieldLimit: 50
    }
  ],
  Basic: [
    {
      planName: "Basic",
      FormLimit: 3,
      EntryLimit: 500,
      FieldLimit: 100,
      CustomFieldLimit: 100
    }
  ],
  Plus: [
    {
      planName: "Plus",
      FormLimit: 12,
      EntryLimit: 1500,
      FieldLimit: 250,
      CustomFieldLimit: 250
    }
  ],
  Max: [
    {
      planName: "Max",
      FormLimit: 1000,
      EntryLimit: 7500,
      FieldLimit: 1000,
      CustomFieldLimit:1000
    }
  ]
}


function App() {
  // const { data: teamData, loading: teamLoading, error: teamError } = useQuery(teamSettings, {
  //   variables: { team_id: null},
  // });
  // console.log("TeamSetting",teamSettings)

  const useFormInternalMethods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [board, setboard] = useState([]);
  const [formPlan, setFormPlan] = useState(null);
  const [condition, setCondition] = useState([]);
  const [listOfWorkflows, setListOfWorkflows] = useState([]);
  const [listOfUserTriggers, setListOfUserTriggers] = useState([]);
  const [UserDetails, setUserDetails] = useState([]);
  const [leaderboard, setLeaderboard] = useState({});
  const handleState = (newTrigger) => {
    setListOfUserTriggers((prevTriggerlist) => {
      return [...prevTriggerlist, newTrigger];
    });
  };


  console.log(">> FormValues", formPlan)
  useEffect(() => {
    setFormPlan(PlanDetails["Max"][0])
  }, [])

  const handleWorkflowsList = (newWorkflow) => {
    setListOfWorkflows((prevWorkflowsList) => [
      ...prevWorkflowsList,
      newWorkflow,
    ]);
  };

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <div className="App">
        <FormProvider {...useFormInternalMethods}>
          <ApolloProvider client={client}>
            <userdetails.Provider value={{ UserDetails, setUserDetails }}>
              <FormPlanContext.Provider value={{ formPlan, setFormPlan }}>
                <boardConetxt.Provider value={{ board, setboard }}>
                  <LeaderboardContext.Provider value={{ leaderboard, setLeaderboard }}>
                    <conditionContext.Provider value={{ condition, setCondition }}>
                      <UserTriggersList.Provider
                        value={{ listOfUserTriggers, handleState }}
                      >
                        <WorkflowsList.Provider
                          value={{ listOfWorkflows, handleWorkflowsList }}
                        >

                          <div>

                            <Routes>
                              <Route path="/" element={<PrivateRoute />}>
                                {/* Opp List Routes start */}
                                <Route path="/opportunity/:oppid" element={<Opportunity />}
                                />
                                <Route path="/opportunities/" element={<OpportunitysList />}
                                />
                                <Route path="/calls/" element={<Calls />}
                                />
                                <Route path="/emails/" element={<Emails />}
                                />
                                <Route path="/notes/" element={<Notes />}
                                />
                                <Route path="/text/" element={<Text />}
                                />
                                <Route path="/conversion-ratio/" element={<ConversionRatio />}
                                />
                                <Route path="/leaderboard" element={<NewLeaderBoard />}
                                />

                                {/* Opp List Routes End */}

                                {/* Form Builder Routes Start */}
                                <Route path="/forms/:id/entries/:entryid" element={<FormEntryData />}/>
                              
                                <Route path="/forms" element={<LayoutNavbar />}>
                                  <Route path="/forms/" element={<FormList />} />
                                  <Route
                                    path="/forms/create-form"  p

                                    element={<CreateForm />}
                                  />
                                  <Route
                                    path="/forms/entries/:id"

                                    element={<FormEntries />}
                                  />

                                  <Route path="/forms/build/:id" element={<DashBoardForFormBuilder />}/>
                                  <Route
                                    path="/forms/create-form"

                                    element={<CreateForm />}
                                  />
                                  <Route
                                    path="/forms/settings/:id" element={<SettingsPage />} />


                                  <Route
                                    path="/forms/conditions/:id" element={<ConditionDashboard />} />


                                </Route>
                                {/* Form Builder Routes End */}

                                {/* Automation Builder Routes Start */}
                                <Route
                                  path="/automation-builder/create-trigger"
                                  element={<AutomationBuilderDashboard />}
                                />
                                <Route
                                  path="/automation-builder/"
                                  element={<AutomationBuilderDashboard />}
                                />
                                <Route
                                  path="/automation-builder/create-workflow"
                                  element={<CreateWorkflow />}
                                />
                                <Route
                                  path="/automation-builder/edit-workflow/:workflow_id"
                                  element={<EditWorkflow />}
                                />
                                <Route
                                  path="/automation-builder/workflow/:workflow_id"
                                  element={<ViewOnlyWorkflow />}
                                />
                                {/* Automation Builder Routes End */}

                              </Route>


                              {/* Embedded App Routes  */}
                              <Route path="/createopportunity" element={<CreateOpportunity />} />
                              <Route path="/updateopportunity" element={<UpdateOpportunity />} />
                              <Route path="/list-view" element={<ListView />} />
                              <Route path="/thankyou/" element={<ThankyouPage />} />
                              <Route path="/forms/:id" element={<Preview />} />

                              {/* Embedded App Routes  */}
                             
                             
                              {/* <Route path="/forms/fieldmapping/:id" element={<FieldMappingComponent />} /> */}
                              <Route path="/field-mapping" element={<FieldMappingDashboard/>}/>


                            </Routes>

                          </div>
                        </WorkflowsList.Provider>
                      </UserTriggersList.Provider>
                    </conditionContext.Provider>
                  </LeaderboardContext.Provider>
                </boardConetxt.Provider>
              </FormPlanContext.Provider>
            </userdetails.Provider>
          </ApolloProvider>
        </FormProvider>
      </div>
    </CacheBuster>
  );
}

export default App;
export { UserTriggersList };
export { WorkflowsList };
export { userdetails };

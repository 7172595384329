import Cookies from "js-cookie";
// const API_HOST = "http://localhost:8000/";
const API_HOST = "https://sandbox.datalabz.re/";
// const API_HOST = "https://datalabz.re/";


export const makeApiCall = async (method, url, payload = {}) => {

  var csrfToken = Cookies.get("csrftoken");
  if(method === "GET"){
    try{
      const response = await fetch(`${API_HOST + url}`, {
        method: method,
        headers: {
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error (`Network response was not ok: ${response.status} ${response.statusText}`);
      }
      const resp = await response;
      return resp
    }catch(error){
    }
  }
  if(method === "POST"){
    try{
      const response = await fetch(`${API_HOST + url}`, {
        method: method,
        headers: {
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error (`Network response was not ok: ${response.status} ${response.statusText}`);
      }
      const resp = await response.json();
      return resp;
    }
    catch(error){
      // return error;
    }
  }

};

export const ClosedFormTemplatt=[
    {
        "elementid": "InputField-opp_last_name",
        "type": "InputField",
        "name": "Last Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Last Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-client-type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "9849",
                    "value": "Buyer",
                    "isCheck": false
                },
                {
                    "id": "2200",
                    "value": "Seller",
                    "isCheck": false
                }
            ],
            "label": "Client Type",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Client Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-fub_deal_id",
        "type": "InputField",
        "name": "FUB Deal ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "FUB Deal ID",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-fub-seller-deal-id",
        "name": "FUB Seller Deal ID",
        "type": "InputField",
        "settings": {
            "label": "FUB Seller Deal ID",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-lead_type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "8030",
                    "value": "Agent Generated",
                    "isCheck": false
                },
                {
                    "id": "0755",
                    "value": "Team Generated",
                    "isCheck": false
                }
            ],
            "label": "Lead Type",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Lead Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-transaction-type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": 602,
                    "value": "New Construction",
                    "isCheck": false
                },
                {
                    "id": 463,
                    "value": "Referral",
                    "isCheck": false
                },
                {
                    "id": 865,
                    "value": "Regular Transaction",
                    "isCheck": false
                }
            ],
            "label": "Transaction Type",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Transaction Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-is_there_a_secondary_client?",
        "name": "Is there a secondary client?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "0275",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "1162",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Is there a secondary client?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-secondary_contact",
        "type": "InputField",
        "name": "Secondary Contact",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Contact",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-secondary_contact_email",
        "type": "Email",
        "name": "Secondary Contact Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Contact Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Contact-secondary_contact_phone",
        "type": "InputField",
        "name": "Secondary Contact Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Contact Phone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-signed_vip_agreement",
        "name": "Signed VIP Agreement",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "8030",
                    "value": "VIP Signed",
                    "isCheck": false
                },
                {
                    "id": "0755",
                    "value": "No VIP",
                    "isCheck": false
                }
            ],
            "label": "Signed VIP Agreement",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-did_you_use_the_listing_manager_system_?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "8030",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "0755",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Did you use the listing manager system?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Did you use the listing manager system?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "FreeText-address",
        "type": "FreeText",
        "name": "Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_address",
        "type": "InputField",
        "name": "Address-Street Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Street Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_address2",
        "type": "InputField",
        "name": "Address-Address Line 2",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Address Line 2",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_city",
        "type": "InputField",
        "name": "Address-City",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-City",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_state",
        "type": "InputField",
        "name": "Address-State",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-State",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_postal_code",
        "type": "InputField",
        "name": "Address-Zip Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Zip Code",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-opp_country",
        "type": "DropDown",
        "name": "Address-Country",
        "field_sub_type": "opportunity",
        "settings": {
            "options": [
                {
                    "id": "8030",
                    "value": "United States",
                    "isCheck": false
                },
                {
                    "id": "0755",
                    "value": "Canada",
                    "isCheck": false
                }
            ],
            "label": "Address-Country",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-transaction_amount",
        "type": "NumberField",
        "name": "Transaction Amount",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Transaction Amount",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-buyer_agent_commission_percent",
        "type": "NumberField",
        "name": "Buyer Agent Commission Percent",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Buyer Agent Commission Percent",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-listing_agent_commission_percent",
        "type": "NumberField",
        "settings": {
            "label": "Listing Agent Commission Percent",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Listing Agent Commission Percent"
    },
    {
        "elementid": "Date-opp_settlement_date",
        "type": "Date",
        "name": "Settlement Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Settlement Date",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "TextArea-Notes",
        "type": "TextArea",
        "name": "Notes",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Notes",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp-key",
        "type": "InputField",
        "name": "Opp Key",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Opp Key",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-Follow-up-boss-lead-id",
        "type": "InputField",
        "name": "Follow Up Boss Lead ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Follow Up Boss Lead ID",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    }
]

export const ClosedFormConditions=[
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-is_there_a_secondary_client?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-secondary_contact",
            "Email-secondary_contact_email",
            "Contact-secondary_contact_phone"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Buyer",
                    "operator": "equals",
                    "field_id": "DropDown-client-type"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-signed_vip_agreement"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Seller",
                    "operator": "equals",
                    "field_id": "DropDown-client-type"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-did_you_use_the_listing_manager_system_?"
        ],
        "ConditionName": "Condition Name"
    }
]

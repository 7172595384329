export const AgreementSignedBuyerForm = [
    {
        "elementid": "InputField-opp_last_name",
        "type": "InputField",
        "name": "Last Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Last Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-client-type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "9849",
                    "value": "Buyer",
                    "isCheck": false
                },
                {
                    "id": "2200",
                    "value": "Seller",
                    "isCheck": false
                }
            ],
            "label": "Client Type",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Client Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-fub_deal_id",
        "type": "InputField",
        "name": "FUB Deal ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "FUB Deal ID",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-fub-seller-deal-id",
        "name": "FUB Seller Deal ID",
        "type": "InputField",
        "settings": {
            "label": "FUB Seller Deal ID",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-transaction-type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "9630",
                    "value": "New Build",
                    "isCheck": false
                },
                {
                    "id": "0643",
                    "value": "Short Sale",
                    "isCheck": false
                },
                {
                    "id": "2124",
                    "value": "Traditional",
                    "isCheck": false
                },
                {
                    "id": "9038",
                    "value": "REO",
                    "isCheck": false
                },
                {
                    "id": "4669",
                    "value": "Commercial",
                    "isCheck": false
                },
                {
                    "id": "1275",
                    "value": "Residential-Lease",
                    "isCheck": false
                },
                {
                    "id": "6799",
                    "value": "Commercial-Lease",
                    "isCheck": false
                }
            ],
            "label": "Transaction Type",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Transaction Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-property-type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "2554",
                    "value": "Condo",
                    "isCheck": false
                },
                {
                    "id": "5975",
                    "value": "Land",
                    "isCheck": false
                },
                {
                    "id": "4519",
                    "value": "Mobile Home",
                    "isCheck": false
                },
                {
                    "id": "1660",
                    "value": "New Build",
                    "isCheck": false
                },
                {
                    "id": "0900",
                    "value": "PUD",
                    "isCheck": false
                },
                {
                    "id": "5961",
                    "value": "SFR",
                    "isCheck": false
                },
                {
                    "id": "4369",
                    "value": "Townhouse",
                    "isCheck": false
                }
            ],
            "label": "Property Type",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Property Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-lead_type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "8030",
                    "value": "Agent Generated",
                    "isCheck": false
                },
                {
                    "id": "0755",
                    "value": "Team Generated",
                    "isCheck": false
                }
            ],
            "label": "Lead Type",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Lead Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-rental",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "6189",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "0808",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Rental",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Rental",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "FreeText-address",
        "type": "FreeText",
        "name": "Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_address",
        "type": "InputField",
        "name": "Address-Street Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Street Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_address2",
        "type": "InputField",
        "name": "Address-Address Line 2",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Address Line 2",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_city",
        "type": "InputField",
        "name": "Address-City",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-City",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_state",
        "type": "InputField",
        "name": "Address-State",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-State",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_postal_code",
        "type": "InputField",
        "name": "Address-Zip Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Zip Code",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-opp_country",
        "type": "InputField",
        "name": "Address-Country",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Country",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-gross_commission_percent",
        "type": "NumberField",
        "name": "Gross Commission Percent",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Gross Commission Percent",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-agent_commission_percent",
        "name": "Agent Commission Percent",
        "type": "NumberField",
        "settings": {
            "label": "Agent Commission Percent",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-agent_commission_amount",
        "type": "NumberField",
        "name": "Agent Commission Amount",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Agent Commission Amount",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-referral_fee",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7103",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "0164",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Referral Fee",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Referral Fee",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-referral_fee__company",
        "type": "InputField",
        "name": "Referral Fee: Company",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Referral Fee: Company",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-referral_fee__agent",
        "type": "InputField",
        "name": "Referral Fee: Agent",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Referral Fee: Agent",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-referral_fee__percent",
        "name": "Referral Fee: Percent",
        "type": "NumberField",
        "settings": {
            "label": "Referral Fee: Percent",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-is_there_a_transaction/admin_fee?",
        "name": "Is there a Transaction/Admin fee?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "2942",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "6778",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Is there a Transaction/Admin fee?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-admin_fee",
        "type": "NumberField",
        "name": "Admin Fee",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Admin Fee",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-opp_agreement_signed_date",
        "type": "Date",
        "name": "Agreement Signed Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Agreement Signed Date",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-opp_agreement_expiration_date",
        "type": "Date",
        "name": "Agreement Expiration Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Agreement Expiration Date",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-transaction_coordinator",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "8105",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "6159",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "8219",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Transaction Coordinator",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Transaction Coordinator",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-is_there_a_secondary_client?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "0275",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "1162",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Is there a secondary client?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Is there a secondary client?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-secondary_contact",
        "type": "InputField",
        "name": "Secondary Contact",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Contact",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-secondary_contact_email",
        "type": "Email",
        "name": "Secondary Contact Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Contact Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Contact-secondary_contact_phone",
        "type": "InputField",
        "name": "Secondary Contact Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Contact Phone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-financing_type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7209",
                    "value": "Cash",
                    "isCheck": false
                },
                {
                    "id": "9302",
                    "value": "Conventional",
                    "isCheck": false
                },
                {
                    "id": "3646",
                    "value": "FHA Loan",
                    "isCheck": false
                },
                {
                    "id": "8861",
                    "value": "VA Loan",
                    "isCheck": false
                }
            ],
            "label": "Financing Type",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Financing Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-loan_officer",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "9794",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "7071",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "9637",
                    "value": "Other",
                    "isCheck": false
                }
            ],
            "label": "Loan Officer",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Loan Officer",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-mortgage_company",
        "type": "InputField",
        "name": "Mortgage Company",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Mortgage Company",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-loan_officer_name",
        "type": "InputField",
        "name": "Loan Officer Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Loan Officer Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-loan_officer_phone",
        "type": "InputField",
        "name": "Loan Officer Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Loan Officer Phone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-loan_officer_email",
        "type": "Email",
        "name": "Loan Officer Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Loan Officer Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-attorney_name",
        "type": "InputField",
        "name": "Attorney Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Attorney Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-attorney_email",
        "type": "Email",
        "name": "Attorney Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Attorney Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "TextArea-Notes",
        "type": "TextArea",
        "name": "Notes",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Notes",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp-key",
        "type": "InputField",
        "name": "Opp Key",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Opp Key",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-Follow-up-boss-lead-id",
        "type": "InputField",
        "name": "Follow Up Boss Lead ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Follow Up Boss Lead ID",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    }
]

export const AgreementSignedBuyerFormCondition = [
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-is_there_a_secondary_client?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-secondary_contact",
            "Email-secondary_contact_email",
            "Contact-secondary_contact_phone"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Other",
                    "operator": "equals",
                    "field_id": "DropDown-loan_officer"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-mortgage_company",
            "InputField-loan_officer_name",
            "InputField-loan_officer_phone",
            "Email-loan_officer_email"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Loan",
                    "operator": "contains",
                    "field_id": "DropDown-financing_type"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Conventional",
                    "operator": "equals",
                    "field_id": "DropDown-financing_type"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-loan_officer"
        ],
        "ConditionName": "Condition Name"
    }
]
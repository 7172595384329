export const AgreementSignedSellerForm =[
    {
        "elementid": "InputField-opp_last_name",
        "type": "InputField",
        "name": "Last Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Last Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-client-type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "9849",
                    "value": "Buyer",
                    "isCheck": false
                },
                {
                    "id": "2200",
                    "value": "Seller",
                    "isCheck": false
                }
            ],
            "label": "Client Type",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Client Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-transaction-type",
        "name": "Transaction Type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "9630",
                    "value": "New Build",
                    "isCheck": false
                },
                {
                    "id": "0643",
                    "value": "Short Sale",
                    "isCheck": false
                },
                {
                    "id": "2124",
                    "value": "Traditional",
                    "isCheck": false
                },
                {
                    "id": "9038",
                    "value": "REO",
                    "isCheck": false
                },
                {
                    "id": "4669",
                    "value": "Commercial",
                    "isCheck": false
                },
                {
                    "id": "1275",
                    "value": "Residential-Lease",
                    "isCheck": false
                },
                {
                    "id": "6799",
                    "value": "Commercial-Lease",
                    "isCheck": false
                }
            ],
            "label": "Transaction Type",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-property-type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "2554",
                    "value": "Condo",
                    "isCheck": false
                },
                {
                    "id": "5975",
                    "value": "Land",
                    "isCheck": false
                },
                {
                    "id": "4519",
                    "value": "Mobile Home",
                    "isCheck": false
                },
                {
                    "id": "1660",
                    "value": "New Build",
                    "isCheck": false
                },
                {
                    "id": "0900",
                    "value": "PUD",
                    "isCheck": false
                },
                {
                    "id": "5961",
                    "value": "SFR",
                    "isCheck": false
                },
                {
                    "id": "4369",
                    "value": "Townhouse",
                    "isCheck": false
                }
            ],
            "label": "Property Type",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Property Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-lead_type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "8030",
                    "value": "Agent Generated",
                    "isCheck": false
                },
                {
                    "id": "0755",
                    "value": "Team Generated",
                    "isCheck": false
                }
            ],
            "label": "Lead Type",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Lead Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "FreeText-address",
        "type": "FreeText",
        "name": "Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_address",
        "type": "InputField",
        "name": "Address-Street Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Street Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_address2",
        "type": "InputField",
        "name": "Address-Address Line 2",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Address Line 2",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_city",
        "type": "InputField",
        "name": "Address-City",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-City",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_state",
        "type": "InputField",
        "name": "Address-State",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-State",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_postal_code",
        "type": "InputField",
        "name": "Address-Zip Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Zip Code",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-opp_country",
        "type": "InputField",
        "name": "Address-Country",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Country",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-listing_amount",
        "type": "NumberField",
        "name": "Listing Amount",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Listing Amount",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-gross_commission_percent",
        "type": "NumberField",
        "name": "Gross Commission Percent",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Gross Commission Percent",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-agent_commission_percent",
        "type": "NumberField",
        "name": "Agent Commission Percent",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Agent Commission Percent",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-referral_fee",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7103",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "0164",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Referral Fee",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Referral Fee",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-referral_fee__company",
        "type": "InputField",
        "name": "Referral Fee: Company",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Referral Fee: Company",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-referral_fee__agent",
        "type": "InputField",
        "name": "Referral Fee: Agent",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Referral Fee: Agent",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-referral_fee__percent",
        "type": "NumberField",
        "name": "Referral Fee: Percent",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Referral Fee: Percent",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-is_there_a_transaction/admin_fee?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "2942",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "6778",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Is there a Transaction/Admin fee?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Is there a Transaction/Admin fee?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "NumberField-admin_fee",
        "type": "NumberField",
        "name": "Admin Fee",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Admin Fee",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-opp_agreement_signed_date",
        "type": "Date",
        "name": "Agreement Signed Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Agreement Signed Date",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-opp_agreement_expiration_date",
        "type": "Date",
        "name": "Agreement Expiration Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Agreement Expiration Date",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-open_house_date",
        "type": "Date",
        "settings": {
            "label": "Open House Date",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Open House Date",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Date-mls_live_date",
        "type": "Date",
        "settings": {
            "label": "MLS Live Date",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "MLS Live Date",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Date-opp_forecasted_close_date",
        "type": "Date",
        "name": "Forecasted Close Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Forecasted Close Date",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "CheckBox-31830",
        "type": "CheckBox",
        "name": "CheckBox",
        "settings": {
            "options": [
                {
                    "id": 679,
                    "value": "MLS Coming Soon Listing",
                    "isCheck": false
                },
                {
                    "id": 190,
                    "value": "Zillow Coming Soon Listing",
                    "isCheck": false
                },
                {
                    "id": 250,
                    "value": "Coming Soon Yard Sign",
                    "isCheck": false
                }
            ],
            "label": "Coming Soon Marketing",
            "size": "large",
            "inputType": "text",
            "required": false,
            "minValue": "",
            "maxValue": "",
            "instructions": "",
            "hidden": true
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-staging_needed",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "4208",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "4411",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Staging Needed",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Staging Needed",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-who_is_paying_for_staging?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "9046",
                    "value": "Agent",
                    "isCheck": false
                },
                {
                    "id": "1203",
                    "value": "Client",
                    "isCheck": false
                },
                {
                    "id": "1488",
                    "value": "Team",
                    "isCheck": false
                }
            ],
            "label": "Who is paying for staging?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Who is paying for staging?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-sign_requirements",
        "name": "Sign Requirements",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "8617",
                    "value": "Yard Sign",
                    "isCheck": false
                },
                {
                    "id": "6150",
                    "value": "Yard Sign + Directional Sign",
                    "isCheck": false
                },
                {
                    "id": "7441",
                    "value": "Directional Sign",
                    "isCheck": false
                },
                {
                    "id": "3615",
                    "value": "Window Sign (for condos)",
                    "isCheck": false
                },
                {
                    "id": "3890",
                    "value": "No Sign",
                    "isCheck": false
                }
            ],
            "label": "Sign Requirements",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Date-sign_install_date",
        "type": "Date",
        "settings": {
            "label": "Sign Install Date",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Sign Install Date",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-photo_requirements",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "4937",
                    "value": "Daytime Photos",
                    "isCheck": false
                },
                {
                    "id": "9166",
                    "value": "Daytime & Twilight Photos",
                    "isCheck": false
                }
            ],
            "label": "Photo Requirements",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Photo Requirements",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-photo_notes",
        "type": "InputField",
        "name": "Photo Notes",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Photo Notes",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-does_your_listing_meet_the_twilight_photo_guidelines?",
        "type": "DropDown",
        "name": "Does your listing meet the Twilight Photo Guidelines?",
        "settings": {
            "options": [
                {
                    "id": 271,
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": 230,
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": 489,
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Does your listing meet the Twilight Photo Guidelines?",
            "size": "large",
            "inputType": "text",
            "required": false,
            "minValue": "",
            "maxValue": "",
            "instructions": "",
            "hidden": true
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-video_requirements",
        "name": "Video Requirements",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": 271,
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": 230,
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": 489,
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Video Requirements",
            "size": "large",
            "inputType": "text",
            "required": false,
            "minValue": "",
            "maxValue": "",
            "instructions": "",
            "hidden": true
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-drone_footage",
        "name": "Drone Footage",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": 271,
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": 230,
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": 489,
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Drone Footage",
            "size": "large",
            "inputType": "text",
            "required": false,
            "minValue": "",
            "maxValue": "",
            "instructions": "",
            "hidden": true
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-elevated_photos",
        "name": "Elevated Photos",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": 271,
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": 230,
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": 489,
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Elevated Photos",
            "size": "large",
            "inputType": "text",
            "required": false,
            "minValue": "",
            "maxValue": "",
            "instructions": "",
            "hidden": true
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-direct_mail_marketing",
        "name": "Direct Mail Marketing",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": 271,
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": 230,
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": 489,
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Direct Mail Marketing",
            "size": "large",
            "inputType": "text",
            "required": false,
            "minValue": "",
            "maxValue": "",
            "instructions": "",
            "hidden": true
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-solar",
        "name": "Solar",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": 271,
                    "value": "Other",
                    "isCheck": false
                },
                {
                    "id": 230,
                    "value": "None",
                    "isCheck": false
                },
                {
                    "id": 489,
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Solar",
            "size": "large",
            "inputType": "text",
            "required": false,
            "minValue": "",
            "maxValue": "",
            "instructions": "",
            "hidden": false
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-do_you_have_a_key",
        "name": "Do you have a key",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": 814,
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": 453,
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Do you have a key",
            "size": "large",
            "inputType": "text",
            "required": false,
            "minValue": "",
            "maxValue": "",
            "instructions": ""
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Date-target_photo_date",
        "type": "Date",
        "settings": {
            "label": "Target Photo Date",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Target Photo Date",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-contractor_box_code",
        "type": "InputField",
        "name": "Contractor Box Code",
        "settings": {
            "label": "Contractor Box Code",
            "predefine": "",
            "size": "large",
            "inputType": "text",
            "required": false,
            "minValue": "",
            "maxValue": "",
            "instructions": "",
            "hidden": true
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-do_the_sellers_text",
        "name": "Do the sellers text",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": 271,
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": 230,
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": 489,
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Do the sellers text",
            "size": "large",
            "inputType": "text",
            "required": false,
            "minValue": "",
            "maxValue": "",
            "instructions": "",
            "hidden": true
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-is_this_a_flipped_property_with_an_existing_title_binder?",
        "name": "Is this a flipped property with an existing title binder?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": 271,
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": 230,
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": 489,
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Is this a flipped property with an existing title binder?",
            "size": "large",
            "inputType": "text",
            "required": false,
            "minValue": "",
            "maxValue": "",
            "instructions": "",
            "hidden": true
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-water",
        "name": "Water",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": 271,
                    "value": "Public Water",
                    "isCheck": false
                },
                {
                    "id": 230,
                    "value": "Well Water",
                    "isCheck": false
                },
                {
                    "id": 489,
                    "value": "Unknown",
                    "isCheck": false
                }
            ],
            "label": "Water",
            "size": "large",
            "inputType": "text",
            "required": false,
            "minValue": "",
            "maxValue": "",
            "instructions": "",
            "hidden": true
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-sewer",
        "name": "Sewer",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": 271,
                    "value": "Public Sewer",
                    "isCheck": false
                },
                {
                    "id": 230,
                    "value": "Well Water",
                    "isCheck": false
                },
                {
                    "id": 489,
                    "value": "Unknown",
                    "isCheck": false
                }
            ],
            "label": "Sewer",
            "size": "large",
            "inputType": "text",
            "required": false,
            "minValue": "",
            "maxValue": "",
            "instructions": "",
            "hidden": true
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-year_built",
        "name": "Year Built",
        "type": "InputField",
        "settings": {
            "label": "Year Built",
            "predefine": "",
            "size": "large",
            "inputType": "text",
            "required": false,
            "minValue": "",
            "maxValue": "",
            "instructions": "",
            "hidden": true
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-is_there_a_gate_code",
        "type": "DropDown",
        "name": "Is there a gate code",
        "settings": {
            "options": [
                {
                    "id": 672,
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": 465,
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Is there a gate code",
            "size": "large",
            "inputType": "text",
            "required": false,
            "minValue": "",
            "maxValue": "",
            "instructions": "",
            "hidden": true
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "NumberField-gate_code",
        "type": "NumberField",
        "name": "Gate Code",
        "settings": {
            "label": "Gate Code ",
            "predefine": "",
            "size": "large",
            "inputType": "number",
            "required": false,
            "minValue": "",
            "maxValue": "",
            "instructions": "",
            "hidden": true
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-preferred_mode_of_communication",
        "name": "Preferred Mode of Communication",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": 783,
                    "value": "Text",
                    "isCheck": false
                },
                {
                    "id": 579,
                    "value": "Call",
                    "isCheck": false
                },
                {
                    "id": 387,
                    "value": "Email",
                    "isCheck": false
                }
            ],
            "label": "Preferred Mode of Communication",
            "size": "large",
            "inputType": "text",
            "required": false,
            "minValue": "",
            "maxValue": "",
            "instructions": "",
            "hidden": true
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-is_there_a_lockbox",
        "type": "DropDown",
        "name": "Is there a lockbox",
        "settings": {
            "options": [
                {
                    "id": 738,
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": 274,
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Is there a lockbox",
            "size": "large",
            "inputType": "text",
            "required": false,
            "minValue": "",
            "maxValue": "",
            "instructions": "",
            "hidden": true
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-lockbox_information",
        "name": "LockBox Information",
        "type": "InputField",
        "settings": {
            "label": "LockBox Information",
            "predefine": "",
            "size": "large",
            "inputType": "text",
            "required": false,
            "minValue": "",
            "maxValue": "",
            "instructions": "",
            "hidden": true
        },
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-transaction_coordinator",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "8105",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "6159",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "8219",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Transaction Coordinator",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Transaction Coordinator",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-is_there_hoa",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "5406",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "9774",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Is there HOA",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Is there HOA",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-hoa_contact_name",
        "type": "InputField",
        "name": "HOA Contact Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "HOA Contact Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-hoa_management_company",
        "type": "InputField",
        "name": "HOA Management Company",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "HOA Management Company",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-hoa_management_phone",
        "type": "InputField",
        "name": "HOA Management Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "HOA Management Phone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-is_there_a_secondary_client?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "0275",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "1162",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Is there a secondary client?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Is there a secondary client?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-secondary_contact",
        "type": "InputField",
        "name": "Secondary Contact",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Contact",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-secondary_contact_email",
        "type": "Email",
        "name": "Secondary Contact Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Contact Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Contact-secondary_contact_phone",
        "type": "InputField",
        "name": "Secondary Contact Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Contact Phone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-title_company",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "4096",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "9701",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "6194",
                    "value": "Other",
                    "isCheck": false
                }
            ],
            "label": "Title Company",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Title Company",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-title_company_name",
        "type": "InputField",
        "name": "Title Company Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Title Company Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-title_rep_name",
        "type": "InputField",
        "name": "Title Rep Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Title Rep Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-title_rep_email",
        "type": "Email",
        "name": "Title Rep Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Title Rep Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-escrow_company",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "6501",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "9208",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "6187",
                    "value": "Other",
                    "isCheck": false
                }
            ],
            "label": "Escrow Company",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Escrow Company",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-escrow_company_name",
        "type": "InputField",
        "name": "Escrow Company Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Escrow Company Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-escrow_officer_name",
        "type": "InputField",
        "name": "Escrow Officer Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Escrow Officer Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-escrow_officer_email",
        "type": "Email",
        "name": "Escrow Officer Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Escrow Officer Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-escrow_officer_phone",
        "type": "InputField",
        "name": "Escrow Officer Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Escrow Officer Phone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-occupancy",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7236",
                    "value": "Owner Occupied",
                    "isCheck": false
                },
                {
                    "id": "2073",
                    "value": "Tenant Occupied",
                    "isCheck": false
                }
            ],
            "label": "Occupancy",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Occupancy",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-tenant_name",
        "type": "InputField",
        "name": "Tenant Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Tenant Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-tenant_email",
        "type": "Email",
        "name": "Tenant Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Tenant Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-tenant_phone",
        "type": "InputField",
        "name": "Tenant Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Tenant Phone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "TextArea-type_property_description_for_mls_and_marketing",
        "type": "TextArea",
        "name": "Type Property Description for MLS and Marketing",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Type Property Description for MLS and Marketing",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "TextArea-Notes",
        "type": "TextArea",
        "name": "Notes",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Notes",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp-key",
        "type": "InputField",
        "name": "Opp Key",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Opp Key",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-Follow-up-boss-lead-id",
        "type": "InputField",
        "name": "Follow Up Boss Lead ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Follow Up Boss Lead ID",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    }
]

export const AgreementSignedSellerFormCondition=[
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-referral_fee"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-referral_fee__company",
            "InputField-referral_fee__agent",
            "NumberField-referral_fee__percent"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-is_there_a_transaction/admin_fee?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "NumberField-admin_fee"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-staging_needed"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-who_is_paying_for_staging?"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-is_there_a_gate_code"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "NumberField-gate_code"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Tenant Occupied",
                    "operator": "equals",
                    "field_id": "DropDown-occupancy"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-tenant_name",
            "Email-tenant_email",
            "InputField-tenant_phone"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Daytime Photos",
                    "operator": "equals",
                    "field_id": "DropDown-photo_requirements"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Daytime & Twilight Photos",
                    "operator": "equals",
                    "field_id": "DropDown-photo_requirements"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "Date-target_photo_date",
            "InputField-photo_notes"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Other",
                    "operator": "equals",
                    "field_id": "DropDown-title_company"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-title_company_name",
            "InputField-title_rep_name",
            "Email-title_rep_email"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Other",
                    "operator": "equals",
                    "field_id": "DropDown-escrow_company"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-escrow_company_name",
            "InputField-escrow_officer_name",
            "Email-escrow_officer_email",
            "InputField-escrow_officer_phone"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-is_there_a_secondary_client?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-secondary_contact",
            "Email-secondary_contact_email",
            "Contact-secondary_contact_phone"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-is_there_hoa"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-hoa_contact_name",
            "InputField-hoa_management_company",
            "InputField-hoa_management_phone"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "",
                    "operator": "equals",
                    "field_id": "DropDown-sign_requirements"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "Date-sign_install_date"
        ],
        "ConditionName": "Condition Name"
    }
]
import React, { useContext, useEffect } from "react";
import { ElementContext } from "../../../pages/form-builder/dashboard/SettingContext";
import useFormApiHelper from "../../../utils/useFormApiHelper";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function ElementsSettingsWidget({ BoardDeleteHandler, elementid, board, setboard, element, setDeleteField }) {
  const { setSetElement } = useContext(ElementContext);
  const { CreateFieldFuntion } = useFormApiHelper()
  const { createFieldMutation } = CreateFieldFuntion()
  const [isHidden, setIsHidden] = React.useState(false);

useEffect(() => {
  board.find((item) => {
    if (item.elementid === elementid) {
      item.settings.hidden ? setIsHidden(true) : setIsHidden(false)
    }
  })
}, [board,elementid])


console.log("isHidden", isHidden)
  const handelSettings = (e) => {
    e.preventDefault();
    setSetElement({ elementid: elementid, type: element.type });
   
  }
  const handelCopy = (eleId, e) => {
    const findIndex = board.findIndex((item) => item.elementid === eleId);
    const newBoard = board.find((item) => item.elementid === eleId);
    const newObj = {
      elementid: `${newBoard.type}-${Math.floor(Math.random() * 10000)}`,
      name: `${newBoard.name}-copy`,
      type: newBoard.type,
      settings: newBoard.settings,
    }
    if (findIndex !== -1) {
      const newArr = [...board];
      newArr.splice(findIndex + 1, 0, newObj);
      setboard(newArr);
    }

    createFieldMutation({
      variables: {
        fieldData: {
          field_id: newObj.elementid,
          input_type: newObj.type,
          field_name: newObj.name,
          field_type: "custom",
          settings: newObj.settings,
        }
      }
    })
    const copyText = newObj.name;

    const Myicon = <i className="iconify fs-5" data-icon="noto:clipboard"></i>;

    navigator.clipboard.writeText(copyText).then(() => {
      toast.success("Field Duplicated" + copyText, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2500,
        icon: Myicon,
        hideProgressBar: true,
        pauseOnHover: false,
        closeOnClick: true,
        theme: "dark",
      });
    });



  }
  const handleClick = (e, elementid) => {
    e.preventDefault();
    setDeleteField(elementid);
  }
  console.log("setDeleteField", setDeleteField)
  return (
    <>

      <div className="py-2 fm-widget w-75">
        <span title="Move">
          <i className="iconify me-1 fs-6 popy" data-icon="ci:grid-vertical" />
        </span>

        <span onClick={(e) => { handelCopy(elementid, e) }} title="Duplicate">

          <i className="iconify mx-2 fs-6 popy" data-icon="cil:copy" />
        </span>
        {/* data-bs-toggle="modal" data-bs-target="#option-setting2" */}
        <span data-toggle="modal" onClick={handelSettings} title="Settings">
          <i
            className="iconify mx-2 fs-6 popy"
            data-icon="akar-icons:settings-horizontal"
            id={elementid}
          />
        </span>
        <span
          // className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={(e) => handleClick(e, elementid)}

          //  onClick={(e) => BoardDeleteHandler(e, elementid, board, setboard)} 
          // type="span" class="btn btn-primary" data-toggle="modals" data-target="#exampleModal"       
          title="Delete">
          <i
            className="iconify mx-2 fs-6 popy text-danger"
            data-icon="bx:trash"
            id={elementid}
          />
        </span>
        {isHidden== true &&    <span title="This Field is Hidden">
          <i className="iconify me-1 fs-6 popy" data-icon="iconamoon:eye-off-bold" data-inline="false"/>
        </span> }
        {isHidden== false &&     <span title="This Field is Visible">
          <i className="iconify me-1 fs-6 popy" data-icon="entypo:eye" data-inline="false" />
        </span>}
       

      </div>
    </>
  );
}

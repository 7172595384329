import React, { useContext, useEffect } from "react";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { Controller, useFormContext } from "react-hook-form";



export const NumberFieldUi = ({ elementid, oppFields, showHide, previewFlag = false }) => {
  console.log("NumberFieldUi 3rd level",oppFields,elementid,showHide,previewFlag)
  const { board } = useContext(boardConetxt);

  const ElementSettingsData = board.find(
    (item) => item?.elementid === elementid
  )?.settings || {};

  const {
    label,
    inputType,
    instructions,
    minValue,
    maxValue,
    predefine,
    required,
    hidden,
  } = ElementSettingsData;

  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };

  const disableElementForBuild = window.location.pathname.includes("build");

  const { formState: { errors }, control, setValue } = useFormContext({
    mode: "onFocus",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (oppFields) {
      console.log(`Setting oppFields value: ${oppFields}`);
      setValue(elementid, oppFields);
    }
  }, [oppFields, elementid, setValue]);
  
  // useEffect(() => {
  //   if (predefine) {
  //     console.log(`Setting predefine value: ${predefine}`);
  //     setValue(elementid, predefine);
  //   }
  // }, [predefine, elementid, setValue]);

  const ElementName = board.find(
    (item) => item?.elementid === elementid
  )?.name;

  const checkingValidation = () => {
    if (required === true && showHide === "show") {
      return false;
    } else if (required === false && showHide === "show") {
      return false;
    } else if (required === true && showHide === "hide") {
      return false;
    } else if (required === false && showHide === "hide") {
      return false;
    } else if (required === true  && hidden === true) {
      return false;
    } else if (required === true) {
      return true;
    } else {
      return false;
    }
  };
  const handleInputChange = (event, field) => {
    let value = event.target.value;

    // Remove any non-numeric characters
    value = value.replace(/[^0-9.]/g, ""); // Removes non-numeric characters except for '.'
    
    field.onChange(value);
  };

  const normalizeFieldName = (name) => {
    return name.replace(/[.\s]/g, '_'); // Replace dots and spaces with underscores
  };
  const handleKeyDown = (event) => {
    // Get the key code and prevent non-numeric input when Num Lock is off
    const key = event.key;

    // If key is not numeric (0-9), backspace, or '.' (dot), prevent the input
    if (!/[0-9]/.test(key) && key !== "Backspace" && key !== "Tab" && key !== "ArrowLeft" && key !== "ArrowRight" && key !== ".") {
      event.preventDefault();
    }
  };
  return (
    <div className={previewFlag && GetStyleClasses(elementid) || ""}
      id={elementid} style={initStyle} hidden={hidden && previewFlag}>
      <div className="form-floating w-100 form-group">

        {Object.keys(ElementSettingsData).length === 0 ? (
          <Controller
            control={control}
            name={normalizeFieldName(elementid)}
            rules={{
              maxLength: {
                value: 13,
                message: `Please Enter less than 13 Characters`
              },
              pattern: {
                value: /^\d+(\.\d+)?$/,
                message: "Please Enter Number Only",
              },

            }}
            render={({ field }) => (
              <input
                type="number"
                className="form-control input-bn"
                placeholder="Enter Number"
                disabled={disableElementForBuild}
                onChange={(e) => handleInputChange(e, field)} // Sanitize input
                onKeyDown={handleKeyDown} // Prevent input when Num Lock is off
                {...field}
                // onKeyDown={handleKeyDown} 
              />
            )}
          />
        ) : (
          <Controller
            control={control}
            name={normalizeFieldName(elementid)}
            rules={{
              required: {
                value: checkingValidation(),
                message: "This Field is Required",
              },
              minLength: {
                value: minValue == " " ? 1 : minValue,
                message: `Please Enter more than ${minValue == "" ? 1 : minValue} Characters`
              },
              maxLength: {
                value: maxValue !== "" ? maxValue : 13,
                message: `Please Enter less than ${maxValue !== "" ? maxValue : 13} Characters`
              },
              pattern: {
                value: /^\d+(\.\d+)?$/,
                message: "Please Enter Number Only",
              },
              validate: value => value !== -1 || "Invalid input: cannot be -1"
            }}
            render={({ field }) => (
              <input
                type={inputType == undefined ? "number" : inputType}
                className="form-control input-bn"
                placeholder="Enter Number"
                disabled={disableElementForBuild}
                defaultValue={predefine === " " ? null : predefine}
                onChange={(e) => handleInputChange(e, field)} // Sanitize input
                onKeyDown={handleKeyDown} // Prevent input when Num Lock is off
                {...field}
                // onKeyDown={handleKeyDown} 
              />
            )}
          />
        )}

        <label>
          <span className="d-flex align-items-center">
            {label == undefined || label == "" ? ElementName : label}
            {required ? <span style={{ color: "red" }}>*</span> : ""}
          </span>
        </label>

      </div>
      <small className="text-secondary">{instructions}</small>
      <div className="d-flex flex-column gap-2">
        {errors && (
          <span className="text-danger">
            {errors[elementid]?.message}
          </span>
        )}
      </div>
    </div>
  );
};